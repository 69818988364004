import * as styles from './fixes-status-badge.css.ts'

type FixesStatusBadgeProps = {
  suggestionsCount: number
  link: string
}

export function FixesStatusBadge({ suggestionsCount, link }: FixesStatusBadgeProps) {
  return (
    <a href={link}>
      <span className={styles.badge}>{suggestionsCount}</span>
    </a>
  )
}
