import * as Dialog from '@radix-ui/react-dialog'
import { CheckIcon, Cross2Icon, TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons'
import * as Select from '@radix-ui/react-select'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { mapToolToLogoAndName } from '../routes/analysis-details-page'
import { usePostAnalyzeScan, usePostUploadScan } from '../utils/api-client/user-platform-api-hooks'
import { CreateScanRequestFormData, Tool } from '../utils/api-client/user-platform-api-schemas'
import { useAddToast } from '../utils/higher-order-components/with-toasts'
import { DefaultButton } from './default-button'
import * as styles from './upload-scan-form-drawer.css'

interface UploadScanFormDrawerProps {
  repositoryId: string
  repositoryDisplayName: string
  handleCloseDrawer: () => void
}
const validTools: Tool[] = [Tool.Enum.APPSCAN, Tool.Enum.CHECKMARX, Tool.Enum.CODEQL, Tool.Enum.SEMGREP, Tool.Enum.SNYK]
const tools: Tool[] = Tool.options.filter(tool => validTools.includes(tool as Tool))

export const UploadScanFormDrawer: React.FC<UploadScanFormDrawerProps> = ({
  repositoryId,
  repositoryDisplayName,
  handleCloseDrawer,
}) => {
  const navigate = useNavigate()
  const [selectedTool, setSelectedTool] = useState('')
  const [fileName, setFileName] = useState('')
  const [commitHash, setCommitHash] = useState('')

  const analyzeScanMutation = usePostAnalyzeScan({
    onError: (error: any) => {
      handleAddToastWithTimeout({
        message: <>Analysis trigger failed. You can try to analyze it again later. {error.message}.</>,
        variant: 'error',
      })

      if (error.bodyAsText && typeof error.bodyAsText === 'string') {
        handleAddToastWithTimeout({
          message: <pre style={{ margin: '0px' }}>{error.bodyAsText}</pre>,
          variant: 'error',
        })
      }
    },
  })
  const uploadScanMutation = usePostUploadScan({
    onSuccess: ({ scan_id }) => {
      handleAddToastWithTimeout({
        message: <>Scan uploaded successfully.</>,
        variant: 'success',
      })

      analyzeScanMutation.mutate(
        { scanId: scan_id },
        {
          onSuccess: ({ analysis_id }) => navigate(`/analysis/${analysis_id}/${scan_id}`),
        }
      )
    },
    onError: (error: any) => {
      handleAddToastWithTimeout({
        message: <>{`Unable to upload scan: ${error.message}. Please try again.`}</>,
        variant: 'error',
      })

      if (error.bodyAsText && typeof error.bodyAsText === 'string') {
        handleAddToastWithTimeout({
          message: <pre style={{ margin: '0px' }}>{error.bodyAsText}</pre>,
          variant: 'error',
        })
      }
    },
  })

  const { handleAddToastWithTimeout } = useAddToast()

  const handleCommitHashChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCommitHash(event.target.value)
  }, [])

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const draftFormData = extractData(new FormData(event.currentTarget))
      const { repositoryId, ...verifiedData } = verifyData(draftFormData)
      uploadScanMutation.mutate({ repositoryId, body: verifiedData })
    },
    [uploadScanMutation, selectedTool, repositoryId]
  )

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFileName(event.target.files?.[0]?.name || '')
    } else {
      setFileName('')
    }
  }, [])

  const handleSelectChange = useCallback((value: string) => {
    setSelectedTool(value)
  }, [])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLLabelElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault()
        document.getElementById(`file-${repositoryId}`)?.click()
      }
    },
    [repositoryId]
  )

  const handleClearFile = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      setFileName('')
      const fileInput = document.getElementById(`file-${repositoryId}`) as HTMLInputElement
      if (fileInput) {
        fileInput.value = ''
      }
    },
    [repositoryId]
  )

  return (
    <Dialog.Root open={true} modal={false}>
      <Dialog.Portal>
        <Dialog.Content
          className={styles.content}
          onEscapeKeyDown={event => event.preventDefault()}
          onPointerDownOutside={event => event.preventDefault()}
          onInteractOutside={event => event.preventDefault()}
        >
          <Dialog.Title className={styles.title}>Upload scan results</Dialog.Title>
          <Dialog.Description className={styles.description}>
            Upload SAST tool results for Pixee to analyze
          </Dialog.Description>

          <form onSubmit={handleSubmit} className={styles.formContainer}>
            <div className={styles.flexContainer}>
              <input type="hidden" name="repositoryId" value={repositoryId} />
              <label htmlFor={`repository-${repositoryId}`} className={styles.inputLabel}>
                Repository
              </label>
              <input
                type="text"
                id={`repository-${repositoryId}`}
                name="repository"
                disabled
                defaultValue={repositoryDisplayName}
                className={styles.inputField}
              />
            </div>

            <div className={styles.flexContainer}>
              <input type="hidden" name={`tool`} value={selectedTool} />
              <label htmlFor={`tool-${repositoryId}`} className={styles.inputLabel}>
                Tool
              </label>
              <Select.Root onValueChange={handleSelectChange}>
                <Select.Trigger id={`tool-${repositoryId}`} className={styles.selectTrigger} aria-label="Tool">
                  <Select.Value placeholder="Select a tool" />
                  <Select.Icon>
                    <TriangleDownIcon className={styles.chevronDown} />
                    <TriangleUpIcon className={styles.chevronUp} />
                  </Select.Icon>
                </Select.Trigger>
                <Select.Content
                  position="popper"
                  side="bottom"
                  className={styles.selectContent}
                  sideOffset={-21}
                  alignOffset={0}
                >
                  <Select.Viewport className={styles.selectViewport}>
                    {tools
                      .filter(tool => tool != 'PIXEE')
                      .map(tool => {
                        const { name, logoHref } = mapToolToLogoAndName(tool, 'dark')
                        return (
                          <Select.Item key={tool} value={tool} className={styles.selectItem}>
                            <Select.ItemText asChild>
                              <span className={styles.selectItemText}>
                                <img
                                  src={logoHref}
                                  alt={`${name} logo`}
                                  width="16"
                                  height="16"
                                  style={{ marginRight: '8px' }}
                                />
                                {name}
                              </span>
                            </Select.ItemText>
                            <Select.ItemIndicator>
                              <CheckIcon className={styles.checkboxIcon} />
                            </Select.ItemIndicator>
                          </Select.Item>
                        )
                      })}
                  </Select.Viewport>
                </Select.Content>
              </Select.Root>
            </div>

            <div className={styles.flexContainer}>
              <input type="file" id={`file-${repositoryId}`} name="file" hidden onChange={handleFileChange} />
              <label htmlFor={`file-${repositoryId}`} className={styles.inputLabel}>
                File
              </label>
              <label
                htmlFor={`file-${repositoryId}`}
                className={styles.fileInputField}
                tabIndex={0}
                onKeyDown={handleKeyDown}
              >
                {fileName ? (
                  <>
                    {fileName}
                    <Cross2Icon className={styles.closeIcon} onClick={handleClearFile} />
                  </>
                ) : (
                  'Select...'
                )}
              </label>
              <span className={styles.inputTrailer}>Results are in SARIF format. Example: cx_results.sarif</span>
            </div>

            <div className={styles.flexContainer}>
              <label htmlFor={`gitCommitHash-${repositoryId}`} className={styles.inputLabel}>
                Git commit hash (optional)
              </label>
              <input
                type="text"
                id={`gitCommitHash-${repositoryId}`}
                name="gitCommitHash"
                className={styles.inputField}
                placeholder="Ex: a1b2c3d4e5f67890abcdef1234567890abcdef12"
                value={commitHash}
                onChange={handleCommitHashChange}
              />
            </div>

            <div className={styles.uploadButtonContainer}>
              <DefaultButton
                buttonType="primary"
                buttonRole="submit"
                state={!selectedTool || !fileName ? 'disabled' : uploadScanMutation.isPending ? 'loading' : 'default'}
              >
                Upload
              </DefaultButton>
            </div>
          </form>

          <Dialog.Close asChild className={styles.closeButton}>
            <button aria-label="Close" onClick={handleCloseDrawer}>
              <Cross2Icon className={styles.closeButtonIcon} />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const extractData = (formData: FormData): any => {
  const data: any = {}
  formData.forEach((value, key) => {
    if (value instanceof File) {
      data[key] = value
    } else {
      data[key] = value.toString()
    }
  })
  return data
}

const verifyData = (data: any): CreateScanRequestFormData & { repositoryId: string } =>
  CreateScanRequestFormData.extend({
    repositoryId: z.string(),
  }).parse({
    repositoryId: data.repositoryId,
    metadata: {
      tool: (data.tool as string).toLowerCase(),
      sha: data.gitCommitHash,
    },
    files: {
      [data.file.name]: data.file,
    },
  })
