import { MarkGithubIcon } from '@primer/octicons-react'
import * as Dialog from '@radix-ui/react-dialog'
import { CheckIcon, Cross1Icon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import {
  ColumnDef,
  RowSelectionState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import classNames from 'classnames'
import fuzzysort from 'fuzzysort'
import { useCallback, useMemo, useState } from 'react'
import { Checkbox } from '../components/checkbox.tsx'
import { DefaultButton } from '../components/default-button.tsx'
import { DropdownFilterSelect } from '../components/dropdown-filter-select.tsx'
import { FixesStatusBadge } from '../components/fixes-status-badge.tsx'
import { FullPageSpinner } from '../components/full-page-spinner.tsx'
import { GhostButton } from '../components/ghost-button.tsx'
import { NoInstallationsComponent } from '../components/no-installations-component.tsx'
import { PaginationControls } from '../components/pagination-controls.tsx'
import { TableBodySkeleton } from '../components/table-body-skeleton.tsx'
import { Pagination } from '../components/table.tsx'
import { TextInput } from '../components/text-input.tsx'
import { UploadScanFormDrawer } from '../components/upload-scan-form-drawer.tsx'
import {
  useGetInstallations,
  useGetPixeebotPullRequests,
  useGetRepositoriesActivations,
  useGetRepositoriesV1,
  usePostAddRepository,
  usePutRepositoriesActivationsMutation,
} from '../utils/api-client/user-platform-api-hooks.ts'
import {
  AnyRepositoryResponse,
  PaginatedResponseRepositoryResponse,
  PullRequest,
  RepositoryActivation,
} from '../utils/api-client/user-platform-api-schemas.ts'
import { toggleFilter } from '../utils/functions/toggle-filter.ts'
import { Toast, useAddToast } from '../utils/higher-order-components/with-toasts.tsx'
import { useEnvironmentData } from '../utils/hooks/use-environment-data.ts'
import * as styles from './repositories-page.css.ts'

export function RepositoriesPage() {
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  })

  const { data: repositories, isPending: repositoriesIsPending } = useGetRepositoriesV1({
    pageNumber: pagination.pageIndex,
    pageSize: pagination.pageSize,
  })
  if (!repositoriesIsPending && repositories && repositories.total === 0) return <NoInstallationsComponent />
  return (
    <main className={styles.mainContainer}>
      <RepositoriesTable repositories={repositories} pagination={pagination} setPagination={setPagination} />
    </main>
  )
}

export type RepositoryWithData = {
  activation?: RepositoryActivation
  pixeebotPRs: PullRequest[]
} & AnyRepositoryResponse

type RepositoriesTableProps = {
  repositories: PaginatedResponseRepositoryResponse
  pagination: Pagination
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>
}

export function RepositoriesTable({ repositories, pagination, setPagination }: RepositoriesTableProps) {
  const environmentData = useEnvironmentData()
  const { handleAddToastWithTimeout } = useAddToast()

  const { data: installations } = useGetInstallations()
  const { data: repositoriesActivation } = useGetRepositoriesActivations()
  const { data: allPixeebotPRs } = useGetPixeebotPullRequests(
    installations.map(installation => installation.account.login)
  )
  const queryClient = useQueryClient()
  const updateRepositoriesActivationMutation = usePutRepositoriesActivationsMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['repositories-activations'] }),
  })

  const toRepositoriesWithData = useCallback(createToRepositoriesWithData(repositoriesActivation, allPixeebotPRs), [
    repositoriesActivation,
    allPixeebotPRs,
  ])
  const repositoriesWithData = useMemo(
    () => repositories.items.map(toRepositoriesWithData),
    [repositories, repositoriesActivation, allPixeebotPRs]
  )
  const handleToggleRepositoriesActivation = useCallback(
    createHandleToggleRepositoriesActivation(
      repositoriesActivation,
      handleAddToastWithTimeout,
      updateRepositoriesActivationMutation
    ),
    [repositoriesActivation, handleAddToastWithTimeout, updateRepositoriesActivationMutation]
  )

  const [selectedRepositoryId, setSelectedRepositoryId] = useState<string | null>(null)
  const selectedRepositoryDisplayName = repositoriesWithData.find(
    repository => repository.id === selectedRepositoryId
  )?.name

  const columns = useMemo<ColumnDef<RepositoryWithData>[]>(
    () => createColumnsDefinitions(handleToggleRepositoriesActivation, setSelectedRepositoryId),
    [handleToggleRepositoriesActivation, setSelectedRepositoryId]
  )
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState<{ id: string; value: string[] }[]>([])

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const table = useReactTable({
    getRowId: data => String(data.id),
    data: repositoriesWithData,
    columns,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      globalFilter,
      columnFilters,
      rowSelection,
    },
    globalFilterFn: (row, columnId, value) => {
      const results = fuzzysort.go(value, [row.getValue(columnId)])
      return results.length > 0
    },
    filterFns: {
      select: (row, columnId, value) => {
        const selectedValues = value
        const columnValue: any = row.getValue(columnId)

        return selectedValues.some(value => value === columnValue)
      },
    },
  })

  if (updateRepositoriesActivationMutation.isPending) return <FullPageSpinner />

  return (
    <div className={styles.topLevelContainer}>
      {selectedRepositoryId && selectedRepositoryDisplayName && (
        <UploadScanFormDrawer
          repositoryId={selectedRepositoryId}
          repositoryDisplayName={selectedRepositoryDisplayName}
          handleCloseDrawer={() => setSelectedRepositoryId(null)}
        />
      )}
      <div className={styles.tableControls}>
        <div>
          <h4 className={styles.tableHeading}>
            Repositories <span className={styles.installationsCount}>({repositories.total})</span>
          </h4>
          <p className={styles.tableDescription}>
            Locations where {environmentData.githubAppName} has been configured for analysis
          </p>
        </div>

        <div className={styles.tableControlsRight}>
          <TextInput value={globalFilter ?? ''} onChange={value => setGlobalFilter(String(value))} size="small" />
          <DropdownFilterSelect
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            toggleAnalysisColumnFilter={(value: string) => () => {
              setColumnFilters(previousFilters => toggleFilter('status')(previousFilters, value))
            }}
            toggleStatusColumnFilter={(value: string) => () => {
              setColumnFilters(previousFilters => toggleFilter('active')(previousFilters, value))
            }}
          />
          <AddRepositoryModal />
        </div>
      </div>
      <table className={styles.table}>
        <thead className={styles.tableHead}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    className={classNames(styles.columnHeader, styles.cell, styles.installationsTableCell)}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        {repositoriesWithData.length === 0 ? (
          <TableBodySkeleton rowCount={7} columnCount={columns.length} rowHeightInPixels={43} />
        ) : (
          <tbody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <tr key={row.id} className={styles.row}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className={classNames(styles.cell, styles.installationsTableCell)}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td className={classNames(styles.cell, styles.emptyStateCell)} colSpan={columns.length}>
                  <div className={styles.emptyStateText}>No results found</div>
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
      <div className={styles.tableFooter}>
        <PaginationControls
          tableLabel="Installations"
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          rowCount={repositories.total}
          onNextPage={() => {
            setPagination(prevState => ({
              pageIndex: prevState.pageIndex + 1,
              pageSize: prevState.pageSize,
            }))
          }}
          onPreviousPage={() =>
            setPagination(prevState => ({
              pageIndex: prevState.pageIndex - 1,
              pageSize: prevState.pageSize,
            }))
          }
          onChangePageSize={value => setPagination({ pageIndex: 0, pageSize: value })}
        />
      </div>
      {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && (
        <div className={styles.bulkActionPromptContainer}>
          <div style={{ display: 'flex', gap: '20px' }}>
            <p className={styles.selectedBulkActionText}>
              <CheckIcon className={styles.checkIconBulkAction} />
              {Object.keys(rowSelection).length} selected
            </p>
            <GhostButton size="small" type="secondary" onClick={() => setRowSelection({})}>
              <Cross1Icon width={13} height={13} className={styles.clearButtonIcon} />
              Clear
            </GhostButton>
          </div>
          <div style={{ display: 'flex', gap: '12px' }}>
            <GhostButton
              size="small"
              type="secondary"
              onClick={() => {
                handleToggleRepositoriesActivation(
                  Object.keys(rowSelection).map(x => Number(x)),
                  false
                )
                setRowSelection({})
              }}
              state={
                repositoriesWithData
                  .filter(repository => Object.keys(rowSelection).includes(String(repository.id)))
                  .some(repository => repository.activation?.user_activated)
                  ? 'default'
                  : 'disabled'
              }
            >
              Disable all
            </GhostButton>
            <DefaultButton
              size="small"
              onClick={() => {
                handleToggleRepositoriesActivation(
                  Object.keys(rowSelection).map(x => Number(x)),
                  true
                )
                setRowSelection({})
              }}
              state={
                repositoriesWithData
                  .filter(repository => Object.keys(rowSelection).includes(String(repository.id)))
                  .some(repository => !repository.activation?.user_activated)
                  ? 'default'
                  : 'disabled'
              }
            >
              Activate all
            </DefaultButton>
          </div>
        </div>
      )}
    </div>
  )
}

const createToRepositoriesWithData =
  (repositoriesActivation: RepositoryActivation[], allPixeebotPRs: PullRequest[]) =>
  (repository: AnyRepositoryResponse): RepositoryWithData => {
    const repositoryActivation = repositoriesActivation.find(
      repositoryActivation => repositoryActivation.repository_id.toString() === repository.id
    )

    const repositoryPixeebotPRs = allPixeebotPRs.filter(
      pullRequest => pullRequest.repository_url === `https://api.github.com/repos/${repository.name}`
    )

    return {
      ...repository,
      activation: repositoryActivation,
      pixeebotPRs: repositoryPixeebotPRs,
    }
  }

type RepoLocationProps = {
  repositoryWithData: RepositoryWithData
}

function RepoLocation({ repositoryWithData }: RepoLocationProps) {
  let isActive =
    (repositoryWithData.activation?.pixee_approved && repositoryWithData.activation?.user_activated) ?? false
  isActive = isActive || repositoryWithData.pixeebotPRs.length > 0
  //  const activeVariant = isActive ? 'active' : 'inactive'
  return (
    <span className={styles.repositoryName}>
      {repositoryWithData.type == 'github' && <MarkGithubIcon className={styles.scmIcon} />}
      {repositoryWithData.name}
    </span>
  )
}

type RepoToggleActivationButtonProps = {
  repositoryName: string
  repositoryActivation?: RepositoryActivation
  handleToggleRepositoriesActivation: (repositoriesIds: number[], toggleState?: boolean) => Promise<void>
}

function RepoToggleActivationButton({
  repositoryActivation,
  handleToggleRepositoriesActivation,
}: RepoToggleActivationButtonProps) {
  return (
    <>
      {repositoryActivation ? (
        <div className="form-check form-switch">
          <input
            className={`form-check-input ${repositoryActivation.user_activated ? styles.toggleButton : styles.toggleButtonOff}`}
            type="checkbox"
            role="switch"
            checked={repositoryActivation.user_activated}
            onChange={() => handleToggleRepositoriesActivation([repositoryActivation.repository_id])}
          />
        </div>
      ) : (
        <div className="form-check form-switch">
          <input
            className={`form-check-input ${styles.toggleButtonDisabled}`}
            type="checkbox"
            role="switch"
            checked
            disabled
          />
        </div>
      )}
    </>
  )
}

const createHandleToggleRepositoriesActivation =
  (
    repositoriesActivation: RepositoryActivation[],
    handleAddToastWithTimeout: (toast: Toast) => void,
    updateRepositoriesActivationMutation: ReturnType<typeof usePutRepositoriesActivationsMutation>
  ) =>
  async (repositoryIds: number[], toggleState?: boolean) => {
    const repositoriesActivationToToggle = repositoriesActivation.filter(({ repository_id }) =>
      repositoryIds.includes(repository_id)
    )
    if (repositoriesActivationToToggle.length === 0) return

    try {
      await updateRepositoriesActivationMutation.mutateAsync({
        repositoryActivations: repositoriesActivationToToggle.map(
          ({ repository_id, user_activated, pixee_approved }) => ({
            repository_id,
            user_activated: toggleState ?? !user_activated,
            pixee_approved,
          })
        ),
      })
      handleAddToastWithTimeout({
        id: '1',
        message: (
          <>
            {`${repositoriesActivationToToggle.length > 1 ? `${repositoriesActivationToToggle.length} repositories were` : 'The repository was'} successfully updated`}
          </>
        ),
        variant: 'success',
      })
    } catch (error: any) {
      handleAddToastWithTimeout({
        id: '1',
        message: <>There was an error updating repositories</>,
        variant: 'error',
      })
    }
  }

const createColumnsDefinitions = (
  handleToggleRepositoriesActivation: ReturnType<typeof createHandleToggleRepositoriesActivation>,
  setSelectedRepositoryId: React.Dispatch<React.SetStateAction<string | null>>
): ColumnDef<RepositoryWithData>[] => {
  const columnHelper = createColumnHelper<RepositoryWithData>()

  return [
    columnHelper.display({
      id: 'checkbox',
      cell: ({ row }) => <Checkbox checked={row.getIsSelected()} onCheckedChange={row.getToggleSelectedHandler()} />,
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          onCheckedChange={checked => table.getToggleAllRowsSelectedHandler()({ target: { checked } })}
        />
      ),
    }),
    columnHelper.accessor((row: RepositoryWithData): string => row.name, {
      id: 'repository',
      cell: ({ row }) => <RepoLocation repositoryWithData={row.original} />,
      header: () => <span>REPOSITORY</span>,
    }) as ColumnDef<RepositoryWithData>,
    columnHelper.display({
      id: 'fixes',
      cell: ({ row }) => {
        const openPRs = row.original.pixeebotPRs.filter(pr => pr.closed_at === null)
        return openPRs.length > 0 ? (
          <div className={styles.center}>
            <FixesStatusBadge
              suggestionsCount={openPRs.length}
              link={`https://github.com/${row.original.name}/pulls`}
            />
          </div>
        ) : (
          <></>
        )
      },
      header: ({}) => <span className={styles.center}>OPEN FIXES</span>,
    }),
    columnHelper.display({
      id: 'analysis',
      cell: ({ row }) => (
        <div className={styles.center}>
          <DefaultButton size="small" onClick={() => setSelectedRepositoryId(row.original.id)}>
            Upload scan results
          </DefaultButton>
        </div>
      ),
      header: ({}) => (
        <>
          <span className={styles.center}>ANALYSIS</span>
        </>
      ),
    }),
    columnHelper.accessor(({ activation }) => (activation?.user_activated ?? false).toString(), {
      id: 'active',
      cell: ({ row }) => (
        <div className={styles.center}>
          <RepoToggleActivationButton
            repositoryName={row.original.name}
            repositoryActivation={row.original.activation}
            handleToggleRepositoriesActivation={handleToggleRepositoriesActivation}
          />
        </div>
      ),
      header: () => <span className={styles.center}>ACTIVE</span>,
      // @ts-expect-error
      filterFn: 'select',
    }),
  ]
}

const AddRepositoryModal: React.FC<{}> = () => {
  const { handleAddToastWithTimeout } = useAddToast()
  const [selectedConnectionType, setSelectedConnectionType] = useState('pixeebotApp')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [url, setUrl] = useState('')
  const [addRepositoryDialogOpen, setAddRepositoryDialogOpen] = useState(false)
  const queryClient = useQueryClient()
  const addRepositoryMutation = usePostAddRepository({
    onSuccess: () => {
      handleAddToastWithTimeout({ message: <>Repository was successfully connected.</>, variant: 'success' })
      queryClient.invalidateQueries({ queryKey: ['repositoriesV1'] })
      setAddRepositoryDialogOpen(false)
    },
    onError: (error: any) => {
      console.error(error)
      handleAddToastWithTimeout({
        message: <>Repository connection failed with error: {error.message}.</>,
        variant: 'error',
      })
      if (error.bodyAsText && typeof error.bodyAsText === 'string') {
        handleAddToastWithTimeout({
          message: <pre style={{ margin: '0px' }}>{error.bodyAsText}</pre>,
          variant: 'error',
        })
      }
    },
  })
  const extractRepoName = (url: string): string => {
    const match = url.match(/\/([^\/]+)\.git$/)
    return match ? (match[1] ?? url) : url
  }

  return (
    <Dialog.Root open={addRepositoryDialogOpen} onOpenChange={setAddRepositoryDialogOpen}>
      <Dialog.Trigger asChild>
        <DefaultButton size="small" buttonType="secondary">
          + &nbsp; Add Repository
        </DefaultButton>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.dialogOverlay} />
        <Dialog.Content className={styles.dialogContent} aria-describedby={undefined}>
          <Dialog.Title>Add Repository</Dialog.Title>
          <form>
            <div className={styles.radioGroup}>
              <label className={styles.radioButton}>
                <input
                  type="radio"
                  name="connectionType"
                  value="pixeebotApp"
                  checked={selectedConnectionType === 'pixeebotApp'}
                  onChange={() => setSelectedConnectionType('pixeebotApp')}
                />
                Install the Pixeebot GitHub application (GitHub repositories only)
              </label>
              <label className={styles.radioButton}>
                <input
                  type="radio"
                  name="connectionType"
                  value="gitClient"
                  checked={selectedConnectionType === 'gitClient'}
                  onChange={() => setSelectedConnectionType('gitClient')}
                />
                Connect via Git client (clone a repository from any SCM)
              </label>
            </div>
            {selectedConnectionType !== 'pixeebotApp' && (
              <div className={styles.inputGroup}>
                <label className={styles.inputLabel}>
                  URL
                  <input
                    type="text"
                    name="url"
                    placeholder="ex: https://scm.com/org/repo-lang.git"
                    className={styles.inputField}
                    value={url}
                    onChange={e => setUrl(e.target.value)}
                  />
                </label>
                <label className={styles.inputLabel}>
                  Username
                  <input
                    type="text"
                    name="username"
                    placeholder="Enter username"
                    className={styles.inputField}
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                  />
                </label>
                <label className={styles.inputLabel}>
                  Password
                  <input
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    className={styles.inputField}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </label>
              </div>
            )}
            <div className={styles.buttonGroup}>
              <Dialog.Close asChild>
                <DefaultButton buttonType="secondary">Cancel</DefaultButton>
              </Dialog.Close>
              {selectedConnectionType !== 'pixeebotApp' ? (
                <DefaultButton
                  buttonType="primary"
                  onClick={() =>
                    addRepositoryMutation.mutate({
                      name: extractRepoName(url),
                      credentials: { username: username, password: password },
                      url: url,
                    })
                  }
                >
                  Connect
                </DefaultButton>
              ) : (
                <DefaultButton href="https://github.com/apps/pixeebot/installations/new" buttonType="primary">
                  Go to GitHub
                </DefaultButton>
              )}
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
